<template>
  <div>
    <iframe
      class="iframe-preview"
      width="100%"
      :src="ticketurl"
    />
  </div>
</template>

<script>
import { getDeviceId } from "../module/storage"
export default {
  components: {
    
  },
  data: () => ({
    ticketurl: ''
  }),
  watch:{

  },
  created() {
    this.customer_service()
  },
  mounted() {

  },
  methods: {
    customer_service() {
      let paramObj = {
        all: this.$route.query.a,
        did: getDeviceId(),
      }
      if(this.getStorage('member_id')){
        paramObj.member_id = this.getStorage('member_id')
      }
      this.$server.ticketurl(paramObj).then((response) => {
        if(response.code==200){
          //window.open(response.data, '_blank')
          this.ticketurl = window.location.protocol+"//"+window.location.host+response.data
        }
      })
    },
  }
}
</script>

<style>
  .iframe-preview{
    position:absolute;
    height:calc(100% - 0px);
    width:100%;
    border:none;
    margin-top:0px;
    z-index: 1;
  }
  .iframe-preview-mobile{
    width:375px;
    left:50%;
    transform:translateX(-50%);
  }
</style>